export const environment = {
  production: false,
  apiUrl: 'http://brameon.com:8000/api/v1/',
  token_curp: '89ea5865-25c0-4a4c-86b6-cc2d1f1d1e81',
  token_cp:'18986491-2527-438f-bfa3-4cbb00808d5a',
  dominio:'http://localhost/'
  // token_curp: 'pruebas',
  // token_cp:'pruebas'
  //192.100.1.100:8081
  //192.100.1.175:8000ng ser

  //apiUrl: 'http://pruebas.brameon.com:x8081/api/v1/'
};  