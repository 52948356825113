
<div  *ngIf="data.categoria == 'exito'">
  <div mat-dialog-title>
    <mat-icon  (click)="cerrarModal()" class="hover:cursor-pointer absolute right-1 top-1" matTooltip="Cerrar" matTooltipPosition="right">close</mat-icon>
    <h2 class="text-center font-bold mb-1 poppins-medium  flex flex-row items-center justify-center gap-x-4 exito-titulo" >{{data.titulo}}</h2>
   
  </div>
  <mat-dialog-content class="mat-typography poppins-semibold flex flex-col items-center justify-center" >
      <img src="assets/gifs/hecho.gif" loop="false"   alt="" style="width: 250px;">
      <p class="subtitulo">{{data.subtitulo}}</p>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    
    <!-- <button mat-button class="fondo-naranja-primario" >Guardar</button> -->
  </mat-dialog-actions>
</div>



  
<div  *ngIf="data.categoria == 'descarga'">
  <div mat-dialog-title >
    <mat-icon  (click)="cerrarModal()" class="hover:cursor-pointer absolute right-1 top-1" matTooltip="Cerrar" matTooltipPosition="right">close</mat-icon>
    <h2 class="text-center font-bold mb-1 poppins-medium  flex flex-row items-center justify-center gap-x-4 texto-azul-primario" >{{data.titulo}}</h2>
   
  </div>
  <mat-dialog-content class="mat-typography poppins-semibold flex flex-col items-center justify-center" >
      <mat-spinner class="mt-3"></mat-spinner>
      <p class="subtitulo">{{data.subtitulo}}</p>
  </mat-dialog-content>
  
  <mat-dialog-actions align="end">
  </mat-dialog-actions>
</div>



  