import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalIneComponent } from '../../home/recursos-humanos/empleados/modal-ine/modal-ine.component';

@Component({
  selector: 'app-mensaje-emergente',
  templateUrl: './mensaje-emergente.component.html',
  styleUrl: './mensaje-emergente.component.scss'
})
export class MensajeEmergenteComponent implements OnInit{
  categoria:String="";
  constructor(public dialogRef: MatDialogRef<ModalIneComponent>,  @Inject(MAT_DIALOG_DATA) public data: any, private dialog:MatDialog) { }
  ngOnInit(): void {

  }
  cerrarModal(): void {
    this.dialog.closeAll()
  }

}
