import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/envioronment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MensajeEmergenteComponent } from '../components/mensaje-emergente/mensaje-emergente.component';
import { MatDialog } from '@angular/material/dialog';
import { AbstractControl } from '@angular/forms';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as Excel from 'exceljs/dist/exceljs.min.js';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import moment from 'moment';
import axios from 'axios';
type AOA = any[][];
@Injectable({
  providedIn: 'root'
})
export class UtilidadesService {
  constructor(private snackBar: MatSnackBar, private http: HttpClient, private dialog: MatDialog) { }
  mostrar_mensaje(msg: string, tipo: 'correcto' | 'error' = 'correcto') {
    this.snackBar.open(msg, 'X', {
      duration: 5400,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: tipo
    });
  }

  verificar_curp(curp): Observable<any> {
    return this.http.get('https://api.valida-curp.com.mx/curp/obtener_datos/?token=89ea5865-25c0-4a4c-86b6-cc2d1f1d1e81&curp=PXNE660720HMCXTN06 ', {})
  }

  respuestas(titulo, subtitulo) {

    return this.dialog.open(
      MensajeEmergenteComponent,
      {
        width: '60%',
        minWidth: '300px',
        height: 'auto',
        maxHeight: '90%',
        disableClose: true,
        panelClass: 'custom-dialog-content',
        data: {
          titulo: titulo,
          subtitulo: subtitulo

        }
      }).afterClosed().subscribe(
        data => { },
        error => { }
      )
  }

  /**
   * Formatea los errores de un FormControl en una cadena de texto.
   * 
   * @param formControl El FormControl del cual se obtendrán los errores.
   * @returns Una cadena de texto con los errores formateados.
   */
  public formatearErrores(formControl: AbstractControl): string {
    const errors = formControl.errors;
    if (!errors) { return ''; }

    let errorMessage = '';

    Object.keys(errors).forEach(errorKey => {
      errorMessage += `${errors[errorKey]}. `;
    });

    return errorMessage;
  }

  /**
   * Elimina un error específico de un control.
   * 
   * @param control - El control del formulario del que se eliminará el error.
   * @param error - El nombre del error que se eliminará.
   */
  public quitarError(control: AbstractControl, error: string) {
    const erroresActuales = control.errors;
  
    if (erroresActuales && erroresActuales[error]) {
      delete erroresActuales[error];
      control.setErrors(Object.keys(erroresActuales).length > 0 ? erroresActuales : null);
      control.updateValueAndValidity();
    }
  }
  exportJsonAsExcelFile(json: any[], excelFileName: string, headers?: string[], title?: string[]) {
    var options = {
    };
    if ( headers ) {
      options['header'] = headers;
    }
    if ( title ) {
      options['origin'] = 'A'+(title.length + 2);
    }
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, options );
    // let Heading = [['FirstName', 'Last Name', 'Email']];
    // XLSX.utils.sheet_add_aoa(worksheet, Heading);
    if ( title ) {
      options['origin'] = 'A'+(title.length + 2);
      for ( let i =1; i <= title.length; i++ ) {
        worksheet['A'+i]={t: 's', v: title[i-1]};
      }
    }
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  
  exportArrayAsExcelFile(json: any[], excelFileName: string) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  importExcelFileAsArray( file: File ) : Promise<any[]> {
    return new Promise ( (resolve) => {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary',cellText:false,cellDates:true});

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        resolve( <AOA>(XLSX.utils.sheet_to_json(ws, {raw: false, header: 1, dateNF:'dd/mm/yyyy'})) );
      };
      reader.readAsBinaryString(file)
    });
  }

  arrayToObject( data: any[] ) {
    // Array must include headings in the first row
    var headings = data.shift();
    return data.map( el => {
      var item = {};
      headings.forEach( (key, index) => {
        item[key] = el[index];
      });
      return item;
    });
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + moment().format('YYYY-MM-DD') + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  blobToFile(blob: any, fileName: string) {
    const a = document.createElement('a')
    const objectUrl = URL.createObjectURL(blob)
    a.href = objectUrl
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(objectUrl);

  }

  async generateExcel(data,header,fileName,columns,_business? ) {  
    // Create workbook and worksheet  
    const workbook = new Excel.Workbook();  
    const worksheet = workbook.addWorksheet();  

    // Cell Style : Fill and Header 
    //worksheet.addRow(['Empresa:Hola'])
 
    const headerRow = worksheet.addRow(header);  
 
    headerRow.eachCell((cell, number) => { 
        cell.fill = {  
            type: 'pattern',  
            pattern: 'solid',  
            fgColor: {  
                argb: '4678E5'  
            },  
            bgColor: {  
                argb: '4678E5'  
            },  
        };  
        cell.font = {  
            color: {  
                argb: 'FFFFFFFF',  
            },  
            bold: true  
        }  
        cell.border = {  
            top: {  
                style: 'thin'  
            },  
            left: {  
                style: 'thin'  
            },  
            bottom: {  
                style: 'thin'  
            },  
            right: {  
                style: 'thin'  
            }  
        };  
    });  
    worksheet.columns = columns
    let i = 0;
    data.forEach(d => {
        i+=1;
        const row = worksheet.addRow(d);
        if(fileName=='Bóvedas' && i<data.length){
          worksheet.addRow({})
          let headerRow_ = worksheet.addRow(header);  
          headerRow_.eachCell((cell, number) => { 
            cell.fill = {  
                type: 'pattern',  
                pattern: 'solid',  
                fgColor: {  
                    argb: '4678E5'  
                },  
                bgColor: {  
                    argb: '4678E5'  
                },  
            };  
            cell.font = {  
                color: {  
                    argb: 'FFFFFFFF',  
                },  
                bold: true  
            }  
            cell.border = {  
                top: {  
                    style: 'thin'  
                },  
                left: {  
                    style: 'thin'  
                },  
                bottom: {  
                    style: 'thin'  
                },  
                right: {  
                    style: 'thin'  
                }  
            };  
        });  
        }
        // row.protection = {
        //   locked: false,
        // };
        row.fill = {  
            type: 'pattern',  
            pattern: 'solid',  
            fgColor: {  
                argb: 'FFFFFFFF'  
            }  
        };  
        row.font = {  
            color: {  
                argb: '00000000',  
            },  
            bold: false  
        }  
        row.eachCell((cell, number) => {  
         
            cell.border = {  
                top: {  
                    style: 'thin'  
                },  
                left: {  
                    style: 'thin'  
                },  
                bottom: {  
                    style: 'thin'  
                },  
                right: {  
                    style: 'thin'  
                }  
            }; 
            worksheet.getColumn(number).width = 25;  
        });  
       
    });  
    // worksheet.getColumn(1).width = 20;  
    // worksheet.getColumn(2).width = 20;  
    // worksheet.getColumn(3).width = 40;  
    // worksheet.getColumn(4).width = 20;
    // worksheet.getColumn(5).width = 20;
    // worksheet.getColumn(6).width = 20;
    // worksheet.getColumn(7).width = 20;
    // worksheet.getColumn(8).width = 30;
    // worksheet.getColumn(9).width = 20;
    // worksheet.getColumn(10).width = 20;
    // worksheet.getColumn(11).width = 20;
  
    
    workbook.xlsx.writeBuffer().then((data: any) => { 
        const blob = new Blob([data], {  
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'  
        });  
        FileSaver.saveAs(blob, fileName + '_export_' + moment().format('YYYY-MM-DD') + '_' + new Date().getTime() + EXCEL_EXTENSION);
        this.dialog.closeAll()
    });  
  }  
}
